/******************************************
*  Author : Suraj Sharma   
*  Created On : Sun Mar 07 2021
*  File : TransactionTable.module.css
*******************************************/

.overflow {
  /* --lh: 1.4rem;
  --max-lines: 3; */
  position: relative;
  /* max-height: calc(var(--lh) * var(--max-lines)); */
  overflow: hidden;
  /* padding-right: 1rem; space for ellipsis */
  justify-content: space-between !important;
  text-align: left !important;
}

@media (max-width: 767px) {
  .overflow {
    flex-direction: column;
    text-align: center !important;
  }

  .transactionSelectButton {
    margin-left: 0;
  }
}

.listItemRow {
  display: flex;
  flex-direction: row;
}

.listItem {
  margin: 3px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  text-align: center;
}

.multiTransactionTable {
  display: flex;
  flex-direction: column;
  height: auto;
}

.multiTransactionTableRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto !important;
}

.headerSeparator {
  border-bottom: 1px solid #e9ecef;
}

.multiTransactionTableRow > span {
  font-size: 13px;
  font-weight: bold;
  white-space: wrap;
  text-overflow: ellipsis;
}

@media (max-width: 444px) {
  .multiTransactionTableRow > span {
    font-size: 10px;
    font-weight: bold;
    white-space: wrap;
    text-overflow: ellipsis;
  }
}

.transactionSelectButton {
  background-color: #55b67a;
  color: #e9e9e9;
  font-size: 12px;
  padding: 0px 8px;
  border-radius: 8px;
  margin-left: 5px;
}

.pageButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.nextPage {
  background-color: #55b67a;
  color: #e9e9e9;
  font-size: 13px;
  padding: 4px 12px;
  border-radius: 8px;
}

.prevPage {
  background-color: #55b67a;
  color: #e9e9e9;
  font-size: 13px;
  padding: 4px 12px;
  border-radius: 8px;
  /* margin-right: 30px; */
}

.disabledButton {
  background-color: #5d5f5e;
}

.pageNo {
  background-color: #55b67a;
  width: auto;
  height: 30px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  padding: 0px 3px;
}
