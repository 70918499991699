/******************************************

*  Author : Suraj Sharma   

*  Created On : Mon Jan 25 2021

*  File : PostAuthMap.module.css

*******************************************/


.switcherContainer {

    position: absolute;
  
    width: 346px;
  
    left: calc(50% - 173px);
  
    bottom: 130px;
  
    z-index: 9;
  
    display: flex;
  
    flex-direction: row;
  
    justify-content: center;
  
    align-items: center;
  
  }
  
  
  .floatingBadge {
  
    min-width: 26px;
  
    min-height: 26px;
  
    position: absolute;
  
    right: -10px;
  
    top: -5px;
  
    display: flex;
  
    align-items: center;
  
    justify-content: center;
  
    background-color: #e29030;
  
    font-size: 11px;
  
    font-weight: 800;
  
    color: white;
  
    border-radius: 50%;
  
    padding: 4px;
  
  }
  
  
  @media (min-width: 1000px) {
  
    .switcherContainer {
  
      bottom: 165px;
  
    }
  
  }
  
  
  @media (min-width: 1300px) {
  
    .switcherContainer {
  
      bottom: 210px;
  
    }
  
  }
  
  
  @media (max-width: 341px) {
  
    .switcherContainer {
  
      width: 100vw;
  
      left: calc(50% - 50vw);
  
    }
  
  }
  
  
  .switchItem {
  
    position: relative;
  
    display: flex;
  
    flex-direction: row;
  
    justify-content: center;
  
    align-items: center;
  
    width: 173px;
  
    padding: 10px 0px;
  
  }
  
  
  .activeSwitch {
  
    background-color: #32625d;
  
    color: #e9e9e9;
  
  }
  
  
  .inactiveSwitch {
  
    background-color: #d4d4d4;
  
    color: #32625d;
  
  }
  
  
  .buttonText {
  
    text-transform: uppercase;
  
    font-size: 12px;
  
    font-weight: bold;
  
  }
  
  
  @media (min-width: 1538px) {
  
    .buttonText {
  
      font-size: 14px;
  
    }
  
  }
  
  
  .listsColumn {
  
    display: flex;
  
    flex-direction: column;
  
    justify-content: center;
  
    padding: 10px;
  
  }
  
  
  .transactionSystemButton {
  
    margin-top: 10px;
  
    text-transform: capitalize;
  
    background-color: #32625d;
  
    color: white;
  
  }
  
  
  .listItem {
  
    display: flex;
  
    flex-direction: column;
  
    justify-content: center;
  
    background-color: #32625d;
  
    color: #e9e9e9;
  
    width: 100%;
  
    padding: 10px;
  
    border-radius: 12px;
  
    margin-top: 10px;
  
  }
  
  
  .buttonContainer {
  
    margin-top: 10px;
  
    width: 100%;
  
    display: flex;
  
    flex-direction: row;
  
    justify-content: center;
  
  }
  
  
  .addTreeToForestBtn {
  
    display: flex;
  
    flex-direction: row;
  
    justify-content: center;
  
    align-items: center;
  
    width: 43%;
  
    background-color: #4ebd86;
  
    color: white;
  
    font-weight: bold;
  
    padding: 5px 0px;
  
    border-radius: 20px;
  
    margin: 0 7px;
  
  }
  
  .modal-dialog-design {
  
    top: 80px;
  
    left: 30px;
  
    width: 420px;
    padding-bottom : 80px;
  
  }
  
  @media (max-width: 991px) {
  
    .modal-dialog-design {
  
      top: 20px;
  
      /* height: 90vh; */
  
      border-radius: 5px;
  
    }
  
    .modal-dialog-design > div {
  
      width: 360px;
  
      margin:0 !important;
  
    }
  
  }
  
  @media (max-width: 800px) {
  
    .addTreeToForestBtn {
  
      font-size: 10px;
  
    }
  
  }
  
  
  @media (min-width: 800px) {
  
    .addTreeToForestBtn {
  
      font-size: 12px;
  
    }
  
  }
  
  
  @media (max-width: 767px) {
  
    .modal-dialog-design {
  
      top: 0;
  
      left: 0;
  
      width: 100%;
  
      padding: 0 !important;
  
      height:auto;
  
      background-color: transparent;
  
    }
  
    .modal-dialog-design > div {
  
      margin:20px auto !important;
  
    }
  
    .switcherContainer {
  
      position: fixed;
  
    }
  
  }
  
  @media only screen and (max-width: 400px) {
  
    .switchItem {
  
      width: 120px;
  
    }
  
    .buttonText {
  
      font-size: 10px;
  
    }
  
  }
  
  

  /* designfix pouup */

  .modal-dialog-design {
    left: 40px;
    top: 40px;
    max-height: calc(100vh - 40px);
    padding-bottom: 30px;
  }
@media only screen and (max-width: 991px) {
  .modal-dialog-design {
    width: 360px;
    padding-bottom: 20px;
  }
}


@media only screen and (max-width: 767px) {
  .modal-dialog-design {
    width: 100%;
    overflow:hidden !important;
    left: 0;
    top: 0 !important;
    max-height: none !important;
    transform: scale(0.85);
    transform-origin: top center;
  
  }

  .modal-dialog-design div:global(.modal-dialog) {
    max-width: 85%;
    width: 85%;
  }
  .modal-dialog-design div:global(.modal-body) {
    max-height: calc(90vh - 80px);
  }
}

.modal-dialog-design div:global(.modal-dialog) {
  margin: 0;
  height: 100%;
}


.modal-dialog-design div:global(.modal-content) {
  border: none;
  max-height: 100%;
}

.modal-dialog-design div:global(.modal-body) {
  overflow-y: auto;
}